<template>
  <b-container fluid>
    <b-row v-show="showTimelineFlag" class="bg-white">
      <b-col>
        <b-container fluid>
          <b-row class="my-2">
            <b-col>
              <b-button class="mr-2" variant="outline-primary" v-on:click="timelineHide">hide timeline</b-button>
              <b-button class="mr-2" variant="outline-primary" v-on:click="timelineShowCountries">by countries</b-button>
              <b-button class="mr-2" variant="outline-primary" v-on:click="timelineShow">by sources</b-button>
              <b-button class="mr-2" variant="outline-primary" v-on:click="timelineShowTags">by types</b-button>
              <b-button class="mr-2" variant="outline-primary" v-on:click="timelineShowTopics">by topics</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="futureOrPast==='future'">
            <b-col>
              <div id="visualization"></div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-show="!showTimelineFlag" class="text-white bg-kpmg-dark-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">Events</h1>
      </b-col>
    </b-row>
    <b-row v-show="!showTimelineFlag">
      <b-col>
        <b-container class="my-4">
          <!-- <b-row class="text-center mb-4" align-h="center" v-if="loadingEvents">
            <b-col cols="1">
              <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
            </b-col>
          </b-row> -->
          <b-row class="mb-3">
            <b-col>
              <b-card class="mb-3">
                <b-form-group horizontal class="mt-2 mb-0">
                <b-row class="pl-2">
                  <b-button class="m-2" variant="outline-primary" v-on:click="toggleFuturePast">show {{oppositeFutureOrPast}} events</b-button>
                  <b-button class="m-2" variant="outline-primary" v-on:click="getXls">download</b-button>
                  <b-button class="m-2" v-if="futureOrPast==='future'" variant="outline-primary" v-on:click="timelineShow">show timeline</b-button>
                  <b-button class="m-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'EventAdd' }">add event</b-button>
                </b-row>
                <b-container class="p-0"> showing <strong>{{futureOrPast}}</strong> events </b-container>
                  <b-input-group>
                    <b-form-input v-model="searchFilter" placeholder="Search......" />
                  </b-input-group>
                  <b-row>
                    <b-col>
                      <h6 class="mt-2 mb-0"><strong>Filters</strong></h6>
                      <b-form inline class="mb-4">
                        <label class="m-2 mt-0" for="publishers">Source:</label>
                        <b-form-select id="publishers" class="m-0 w-25" v-model="filterByPublisher" :options="publishers"></b-form-select>
                        <label class="m-2" for="publishers">Type:</label>
                        <b-form-select id="publishers" class="m-0 w-25" v-model="filterByTag" :options="tags" ></b-form-select>
                        <label class="m-2" for="publishers">Topic:</label>
                        <b-form-select id="publishers" class="m-0 mr-2 w-25" v-model="filterByTopic" :options="topics" ></b-form-select>
                        <b-btn class="ml-2" v-on:click="filterReset">reset</b-btn>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <transition name="list" mode="out-in">
                  <p v-if="loading" class="text-center transition-delay: 1.5s"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i> Events loaded: {{events.length}}</p>
              </transition>
              <b-card class="mb-3" v-for="event in results" :key="event.id">
                <event
                  :event="event"
                  :permissions="permissions"
                  :username="user.username"
                  :showRelatedNews="true"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import ac from '../libs/accesscontrol'
import zipcelx from 'zipcelx'

import Event from '@/components/Event.vue'

import { DataSet } from "vis-data/peer"
import { Timeline } from "vis-timeline/peer"
import "vis-timeline/styles/vis-timeline-graph2d.css"

export default {
  components: {
    Event
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    oppositeFutureOrPast: function () {
      if (this.futureOrPast === 'future') {
        return 'past'
      } else {
        return 'future'
      }
    },
    // Filter Drop Downs
    publishers: function () {
      const publishersList = [{ "value": null, "text": " All sources" }]
      _.each(this.events, event => {
        if (event.publications.length > 0) {
          _.each(event.publications, publication => {
            publishersList.push({ "value": publication.publisher, "text": publication.publisher })
          })
        }
      })
      return _.sortedUniqBy(_.sortBy(publishersList, 'text'), 'text')
    },
    tags: function () {
      const tagsList = [{ "value": null, "text": " All types" }]
      _.each(this.events, event => {
        if (event.publications.length > 0) {
          _.each(event.publications, publication => {
            if (publication.tags.length > 0) {
              _.each(publication.tags, tags => {
                tagsList.push({ "value": tags.name, "text": tags.name })
              })
            }
          })
        }
      })
      return _.sortedUniqBy(_.sortBy(tagsList, 'text'), 'text')
    },
    topics: function () {
      const topicsList = [{ "value": null, "text": " All topics" }]
      _.each(this.events, event => {
        if (event.publications.length > 0) {
          _.each(event.publications, publication => {
            if (publication.regtopics.length > 0) {
              _.each(publication.regtopics, topics => {
                topicsList.push({ "value": topics.name, "text": topics.name })
              })
            }
          })
        }
      })
      return _.sortedUniqBy(_.sortBy(topicsList, 'text'), 'text')
    },
    // Filter Results from API
    results: function () {
      let pubResults = []
      if (this.filterByPublisher != null) {
        _.each(this.events, obj => {
          if (obj.publications.length > 0) {
            _.each(obj.publications, publication => {
              if (publication.publisher === this.filterByPublisher) {
                pubResults.push(obj)
              }
            })
          }
        })
      } else {
        pubResults = this.events
      }
      let tagResults = []
      if (this.filterByTag != null) {
        _.each(pubResults, obj => {
          if (obj.publications.length > 0) {
            _.each(obj.publications, publication => {
              if (publication.tags.length > 0) {
                _.each(publication.tags, tags => {
                  if (tags.name === this.filterByTag) {
                    tagResults.push(obj)
                  }
                })
              }
            })
          }
        })
      } else {
        tagResults = pubResults
      }
      let topicResults = []
      if (this.filterByTopic != null) {
        _.each(tagResults, obj => {
          if (obj.publications.length > 0) {
            _.each(obj.publications, publication => {
              if (publication.regtopics.length > 0) {
                _.each(publication.regtopics, topics => {
                  if (topics.name === this.filterByTopic) {
                    topicResults.push(obj)
                  }
                })
              }
            })
          }
        })
      } else {
        topicResults = tagResults
      }
      const searchResults = topicResults.filter(events => {
        return events.name.toUpperCase().match(this.searchFilter.toUpperCase())
      })
      let items = _.slice(_.uniq(searchResults), (this.currentPage - 1) * this.perPage, (this.currentPage - 1) * this.perPage + this.perPage)
      return items
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Events"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'events', action: 'open events' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('event').granted
    this.permissions.delete = ac.can(this.user.acgroups).deleteAny('event').granted
    this.permissions.edit = ac.can(this.user.acgroups).updateAny('event').granted
    this.loadEventsFromApi()
  },
  data () {
    return {
      buttonvariant: 'primary',
      currentPage: 1,
      event: {},
      events: [{
        name: 'updating...',
        dateTime: 'updating...',
        id: 'updating...',
        comments: 'updating...',
        createdBy: 'updating...',
        publications: [
          {
            tags: 'updating...',
            regtopics: 'updating...'
          }
        ]
      }],
      filterByPublisher: null,
      filterByTag: null,
      filterByTopic: null,
      futureOrPast: 'future',
      loadingEvents: true,
      loading: true,
      permissionReadAnalytics: false,
      permissions: {
        add: false,
        delete: false,
        edit: false
      },
      perPage: 100,
      searchFilter: '',
      showSelectionOptions: false,
      showTimelineFlag: false,
      timeline: null
    }
  },
  methods: {
    filterReset: function () {
      this.filterByPublisher = null
      this.filterByTag = null
      this.filterByTopic = null
      this.searchFilter = ''
    },
    getXls: function () {
      let today = new Date().toISOString().slice(0, 10)
      this.$stat.log({ page: 'events', action: 'download events' })
      let data = []
      const headings = [
        { value: 'EventId', type: 'string' },
        { value: 'Date', type: 'string' },
        { value: 'Last Updated', type: 'string' },
        { value: 'Title', type: 'string' },
        { value: 'Details', type: 'string' },
        { value: 'Tags', type: 'string' },
        { value: 'Publisher', type: 'string' },
        { value: 'Regulatory Topics', type: 'string' },
        { value: 'Regulatory Stage', type: 'string' },
        { value: 'Country Code', type: 'string' },
        { value: 'Links', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.events.length; i < len; i++) {
        const tagList = []
        _.each(this.events[i].publications, publication => {
          if (publication.tags.length > 0) {
            _.each(publication.tags, tag => {
              tagList.push(tag.name)
            })
          }
        })
        const publisherList = []
        _.each(this.events[i].publications, publication => {
          if (publication.tags.length > 0) {
            publisherList.push(publication.publisher)
          }
        })
        const regTopicsList = []
        _.each(this.events[i].publications, publication => {
          if (publication.regtopics.length > 0) {
            _.each(publication.regtopics, topics => {
              regTopicsList.push(topics.name)
            })
          }
        })
        const regulatoryStageList = []
        _.each(this.events[i].publications, publication => {
          if (publication.regtopics.length > 0) {
            _.each(publication.regtopics, topics => {
              regulatoryStageList.push(topics.regulatoryStage)
            })
          }
        })
        const countryCodeList = []
        _.each(this.events[i].publications, publication => {
          if (publication.tags.length > 0) {
            countryCodeList.push(publication.countryCode)
          }
        })
        const linkList = []
        _.each(this.events[i].publications, publication => {
          if (publication.tags.length > 0) {
            linkList.push(publication.link)
          }
        })
        let row = [
          { value: this.events[i].id, type: 'string' },
          { value: this.events[i].date, type: 'string' },
          { value: this.events[i].updated_at, type: 'string' },
          { value: this.events[i].name, type: 'string' },
          { value: this.events[i].comments, type: 'string' },
          { value: _.sortedUniqBy(tagList), type: 'string' },
          { value: _.sortedUniqBy(publisherList), type: 'string' },
          { value: _.sortedUniqBy(regTopicsList), type: 'string' },
          { value: _.sortedUniqBy(regulatoryStageList), type: 'string' },
          { value: _.sortedUniqBy(countryCodeList), type: 'string' },
          { value: _.sortedUniqBy(linkList), type: 'string' }

        ]
        data.push(row)
      }
      const config = { filename: this.futureOrPast + '-events-' + today, sheet: { data: data } }
      zipcelx(config)
    },
    loadEventsFromApi: async function () {
      let limit = 500
      let offset = 0
      let response = []
      let page = ''
      this.events = []
      this.loading = true
      this.showSelectionOptions = false
      do {
        offset = page * limit
        let apiName = 'cosmos'
        let path = `/events/${this.futureOrPast}/limit/${limit}/offset/${offset}`
        response = await this.$Amplify.API.get(apiName, path)
        const events = _.concat(this.events, response)
        let order = 'asc'
        if (this.futureOrPast === 'past') {
          order = 'desc'
        }
        this.events = _.orderBy(events, ['date'], [order])
        page++
        await this.$nextTick()
      } while (response.length === limit)
      this.loading = false
    },
    timelineHide: function () {
      this.timeline.destroy()
      this.timeline = null
      this.showTimelineFlag = false
    },
    timelineShow: function () {
      if (this.timeline) {
        this.timeline.destroy()
      }
      this.showTimelineFlag = true
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const groupsData = []
      const timelineData = _.map(this.events, x => {
        const item = { id: x.id, content: x.name, start: x.date }
        let showTags = false
        if (x.publications.length > 0) {
          if (x.publications[0].tags.length > 0) {
            showTags = true
          }
        }
        if (showTags) {
          item.className = 'tag-' + x.publications[0].tags[0].id
        }
        if (x.publications.length > 0) {
          item.group = x.publications[0].publisher
          groupsData.push(x.publications[0].publisher)
        } else {
          item.group = 'none'
          groupsData.push('none')
        }
        return item
      })
      const groupsDataUniq = _.uniq(groupsData)
      const groupsDataObjects = _.map(groupsDataUniq, x => {
        return { id: x, content: x }
      })
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsDataObjects)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(3, 'days'),
        end: moment().add(10, 'days')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    },
    timelineShowCountries: function () {
      this.timeline.destroy()
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const groupsData = []
      const timelineData = _.map(this.events, x => {
        const item = { id: x.id, content: x.name, start: x.date }
        let showTags = false
        if (x.publications.length > 0) {
          if (x.publications[0].tags.length > 0) {
            showTags = true
          }
        }
        if (showTags) {
          item.className = 'tag-' + x.publications[0].tags[0].id
        }
        if (x.publications.length > 0) {
          item.group = x.publications[0].countryCode
          groupsData.push({ id: x.publications[0].countryCode, content: x.publications[0].countryName })
        } else {
          item.group = 'unmapped'
          groupsData.push({ id: 'unmapped', content: 'unmapped' })
        }
        return item
      })
      const groupsDataUniq = _.chain(groupsData).uniqBy('id').sortBy('id').value()
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsDataUniq)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(7, 'days'),
        end: moment().add(1, 'months')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    },
    timelineShowTags: function () {
      this.timeline.destroy()
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const groupsData = []
      const timelineData = _.map(this.events, x => {
        const item = { id: x.id, content: x.name, start: x.date }
        let showTags = false
        if (x.publications.length > 0) {
          if (x.publications[0].tags.length > 0) {
            showTags = true
          }
        }
        if (showTags) {
          item.group = x.publications[0].tags[0].id
          item.className = 'tag-' + x.publications[0].tags[0].id
          groupsData.push({ id: x.publications[0].tags[0].id, content: x.publications[0].tags[0].name })
        } else {
          item.group = 'unmapped'
          groupsData.push({ id: 'unmapped', content: 'unmapped' })
        }
        return item
      })
      const groupsDataUniq = _.chain(groupsData).uniqBy('id').sortBy('id').value()
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsDataUniq)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(7, 'days'),
        end: moment().add(1, 'months')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    },
    timelineShowTopics: function () {
      this.timeline.destroy()
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const groupsData = []
      const timelineData = _.map(this.events, x => {
        const item = { id: x.id, content: x.name, start: x.date }
        let showTopics = false
        if (x.publications.length > 0) {
          if (x.publications[0].regtopics.length > 0) {
            showTopics = true
          }
        }
        if (showTopics) {
          item.group = x.publications[0].regtopics[0].id
          const tag = x.publications[0].tags.length > 0 ? x.publications[0].tags[0].id : ''
          item.className = 'tag-' + tag
          groupsData.push({ id: x.publications[0].regtopics[0].id, content: x.publications[0].regtopics[0].name })
        } else {
          item.group = 'unmapped'
          groupsData.push({ id: 'unmapped', content: 'unmapped' })
        }
        return item
      })
      const groupsDataUniq = _.chain(groupsData).uniqBy('id').sortBy('id').value()
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsDataUniq)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(7, 'days'),
        end: moment().add(1, 'months')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    },
    toggleFuturePast: function () {
      this.$stat.log({ page: 'events', action: 'toggle events future-past' })
      this.futureOrPast = this.oppositeFutureOrPast
      this.loadEventsFromApi()
    }
  },
  watch: {}
}
</script>
<style>
/* Dear CEO */
.vis-item.tag-1 {
  background-color: orange;
}

/* Consultation */
.vis-item.tag-3 {
  color: white;
  background-color: green;
}

/* Discussion paper */
.vis-item.tag-7 {
  background-color: lightgreen;
}

/* Policy statement */
.vis-item.tag-18 {
  background-color: orangered;
}

/* Final rules */
.vis-item.tag-9 {
  background-color: red;
}

/* Guidelines */
.vis-item.tag-18 {
  background-color: darkorange;
}

.list-enter-active, .list-leave-active {
  transition: opacity 2s;
}
.list-enter, .list-leave-to, .fade-leave-active {
  opacity: 0;
}

</style>
