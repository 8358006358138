<template>
  <div class="card-body pb-0" v-if="!deleted">
    <h4 class="card-title">{{event.name}} - [{{event.id}}]</h4>
    <div class="mb-0">
      {{event.date}}
      <span v-if="permissions.delete">
        <b-button variant="outline-primary" size="sm" class="ml-2" v-on:click="deleteEvent">delete</b-button>
      </span>
      <span v-if="permissions.edit">
        <b-button variant="outline-primary" size="sm" class="ml-2" :to="{ name: 'EventEdit', params: { id: event.id }}">edit</b-button>
      </span>
    </div>
    <div class="mb-0"><markdown :content="event.comments" /></div>
    <div class="mb-0 mt-0" v-if="$config.BUILD === 'full'">
      <small class="text-info">created by: {{event.createdBy}}</small>
    </div>
    <div>
      <b-row v-if="loadingRelated" class="text-center mb-4" align-h="center">
        <b-col cols="1">
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col class="text-white bg-kpmg-light-green py-4 m-2" v-if="articles.length > 0 && !loadingArticles">
        <h5 class="card-title font-kpmg font-size-44">Related Insights</h5>
        <ul class="bg-kpmg-light-green pl-0 ml-3 mb-0">
          <div v-for="article in articles" :key="article.id">
            <li><router-link class="text-white" :to="{ name: 'Article', params: { id: article.id }}"><strong>{{article.title}}</strong></router-link></li>
          </div>
        </ul>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="publications.length > 0 && !loadingNews">
      <b-col class="text-white bg-kpmg-light-blue py-4 m-2">
        <h5 class="card-title font-kpmg font-size-44">Related News</h5>
        <ul class="bg-kpmg-light-blue pl-0 ml-3 mb-0">
          <div v-for="publication in publications" :key="publication.id">
            <li><router-link class="text-white" :to="{ name: 'NewsItem', params: { id: publication.id }}"><strong>{{publication.name}}</strong></router-link></li>
          </div>
        </ul>
      </b-col>
      <b-col class="text-white bg-kpmg-light-purple py-4 m-2" v-if="topics.length > 0 && !loadingTopics">
        <h5 class="card-title font-kpmg font-size-44">Related Topics</h5>
        <ul class="bg-kpmg-light-purple pl-0 ml-3 mb-0">
          <div v-for="topic in topics" :key="topic.id">
            <li><router-link class="text-white" :to="{ name: 'Topic', params: { id: topic.id }}"><strong>{{topic.name}}</strong></router-link></li>
          </div>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Markdown from '@/components/Markdown.vue'

export default {
  name: 'Event',
  props: ['event', 'username', 'showRelatedNews', 'permissions', 'publicationId'],
  beforeDestroy () {
  },
  components: {
    Markdown
  },
  computed: {
    ownEvent: function () {
      return this.event.createdBy === this.username
    }
  },
  created: function () {
    this.showPublications()
  },
  data () {
    return {
      articles: [],
      deleted: false,
      loadingPublications: false,
      loadingNews: true,
      loadingRelated: true,
      loadingTopics: false,
      loadingArticles: true,
      publications: [],
      showPublicationFlag: false,
      topics: []
    }
  },
  methods: {
    showPublications: async function () {
      this.showPublicationFlag = true
      this.loadingPublications = true
      this.loadingTopics = true
      try {
        let apiName = 'cosmos'
        let path = `/news/event/${this.event.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.publications = response
        this.loadingNews = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
      try {
        let apiName = 'cosmos'
        let path = `/topics/event/${this.event.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.topics = response
        this.loadingTopics = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
      try {
        let apiName = 'cosmos'
        let path = `/articles/event/limit/5/${this.event.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.articles = response
        this.loadingArticles = false
      } catch (e) {
        this.$logger.error('saved ERROR: ' + e)
      }
      this.loadingRelated = false
    }
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
